<footer>
    <div class="container-lg">
        <div class="row">
            <div class="col-12 col-lg-8 mt-3 mt-lg-0">
                <div class="row">
                    <div class="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="assets/layout/images/logo/itknowation.png">
                    </div>
                    <div class="col-4 d-none d-lg-block">
                        <ul class="list-unstyled">
                            <li>itknowation GmbH</li>
                            <li>Freindorfer Straße 4</li>
                            <li>A-4052 Ansfelden</li>
                        </ul>
                    </div>
                    <div class="col-4 d-none d-lg-block">
                        <ul class="list-unstyled">
                            <li>Tel: +43(0)732 27 27 28 0</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 d-flex flex-column flex-lg-row align-items-center justify-content-between order-first order-lg-last">
                <div *ngFor="let linkItem of links">
                    <a class="hrep-txt-primary text-decoration-none text-uppercase"
                       href="{{linkItem.url}}" target="_blank">{{linkItem.text}}</a>
                </div>
            </div>
        </div>
    </div>
</footer>
