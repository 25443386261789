<div class="row mb-3">
  <div class="d-block col-12 d-md-none">
    <app-search-box></app-search-box>
  </div>
</div>
<div class="row mt-lg-5">
    <div *ngFor="let action of mainActions" class="col-4 col-md-4 mb-2">
        <div class="hrep-btn-round btn-large">
            <a routerLink="{{action.url}}" ejs-button cssClass="e-round" iconCss="{{ action.icon }}"></a>
            <div class="text-center">
                <p>{{ action.text }}</p>
            </div>
        </div>
    </div>
</div>
