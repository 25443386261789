import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Response} from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private baseUrl: string = environment.ENTITY_MANAGER_URL_PREFIX;

  constructor(
    protected http: HttpClient,
  ) { }

  public fetchEntities<T>(entityUrl: string, query?: { [key: string]: string }): Observable<Response<T>> {
    const params = new HttpParams({ fromObject: query });
    return this.http.get<Response<T>>(`${this.baseUrl}${entityUrl}`, { params });
  }

  public createEntity<T>(entityUrl: string, body: Record<string, unknown>): Observable<Response<T>> {
    return this.http.post<Response<T>>(`${this.baseUrl}${entityUrl}`, body);
  }

  public fetchEntity<T>(entityUrl: string, id: string): Observable<Response<T>> {
    const apiUrl = `${this.baseUrl}${entityUrl}`;
    const url = this.getEntityUrl(apiUrl, id);
    return this.http.get<Response<T>>(url);
  }

  public updateEntity<T>(entityUrl: string, id: string, body: T): Observable<Response<T>> {
    const apiUrl = `${this.baseUrl}${entityUrl}`;
    const url = this.getEntityUrl(apiUrl, id);
    return this.http.put<Response<T>>(url, body);
  }

  public deleteEntity<T>(entityUrl: string, id: string): Observable<Response<T>> {
    const apiUrl = `${this.baseUrl}${entityUrl}`;
    const url = this.getEntityUrl(apiUrl, id);
    return this.http.delete<Response<T>>(url);
  }

  protected getEntityUrl(url: string, id: string): string {
    return [url, id].join('/');
  }
}
