<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">Dokumenten Upload</h6>
    </div>
    <div class="hrep-accordion accordion-light">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>Dokumente</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='documents' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='created_at' headerText='Datum' textAlign='Center'
                                          type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                          width='100'></e-column>
                                <e-column field='name' headerText='Bezeichnung' textAlign='Center'
                                          width='auto'></e-column>
                                <e-column  width='auto' textAlign='Center'>
                                    <ng-template #template let-data>
                                        <div>
                                            <button (click)="onClickUpload($event)" ejs-button cssClass="hrep-icon-btn" iconCss="icon-cloud-upload {{data.file ? 'green' : 'red'}}"></button>
                                        </div>
                                        <div id='dropArea'>
                                            <ejs-uploader
                                                          #defaultupload
                                                          [autoUpload]='true'
                                                          (selected)="upload($event, data)"
                                                          [buttons]="buttons"
                                                          multiple='false'
                                                          class="hrep-file-upload">
                                                <ng-template #template let-data="">
                                                    <span class='file-name'>{{data.name}}</span>
                                                </ng-template>
                                            </ejs-uploader>
                                        </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
</div>

