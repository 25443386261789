import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from '../../../../../core/services/crud.service';
import {DialogComponent} from "@syncfusion/ej2-angular-popups";
import {AuthenticationService} from '../../../../../core/services/authentication.service';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageItemComponent {
  public isOpened = false;
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  public targetElement: HTMLElement | undefined;

  @Input() listItem: any;

  @Input() set isExpanded(isExpanded: boolean) {
    if (!this._isExpanded && isExpanded) {
      this.markAsRead()
    }
    this._isExpanded = isExpanded;
  }
  @Output() public messageOpened = new EventEmitter<number>();
  public showReplyBox = false;
  public _isExpanded= false;
  public replayText= '';


  constructor(
    private router: Router,
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
  ) {
  }

  public onShowReplyBox(event: Event): void {
    event.stopPropagation();
    this.isExpanded = true;
    this.showReplyBox = !this.showReplyBox;
    this.markAsRead();
  }

  public sendReplay(event: Event): void {
    event.stopPropagation();
    this.crudService.createEntity('memo', {
      text: this.replayText,
      user: {id:this.authService.getUser()}
    }).subscribe(message => {
      this.replayText = '';
      this.showReplyBox = false;
      this.cd.detectChanges();
    });
  }

  protected markAsRead(): void {
    if (this.listItem.is_read === 0) {
      this.listItem.is_read = 1;
      this.crudService.updateEntity('message', this.listItem.id, this.listItem).subscribe(message => {
        this.cd.detectChanges();
      })
    }
  }

  public downloadAttachment(event: Event) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = this.listItem.file.file_url; //Image Base64 Goes here
    a.download = this.listItem.file.file_name; //File name Here
    a.target = '_blank';
    console.log(a);
    a.click();
  }

  public onOpenDialog = (): void => {
    this.isOpened = true;
    this.ejDialog?.show();
  };

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }
}

