import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Response} from '../models/response';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private baseUrl: string = environment.ENTITY_MANAGER_URL_PREFIX;

  constructor(protected http: HttpClient,
  ) {
  }

  public upload(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(
      environment.ENTITY_MANAGER_URL_PREFIX + 'file/upload',
      formData);
  }
}
