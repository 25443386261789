<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">Upload Zeitscheine</h6>
    </div>
    <div class="hrep-accordion accordion-light">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>Zeitscheine</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='timesheets' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='from' headerText='von' textAlign='Center'
                                          type='date'
                                          [format]="format"
                                          width='100'></e-column>
                                <e-column field='to' headerText='bis' textAlign='Center'
                                          type='date'
                                          [format]="format"
                                          width='100'></e-column>
                                <e-column field='assignment.customer' headerText='Kunde' textAlign='Center'
                                          width='auto'></e-column>
                                <e-column width='50' textAlign='Center'>
                                    <ng-template #template let-data>
                                      <div class="d-flex align-items-center">
                                        <button (click)="onClickUpload($event)" ejs-button cssClass="hrep-icon-btn {{data.file ? 'green' : 'red'}}" iconCss="icon-cloud-upload"></button>
                                        <div id='dropArea'>
                                            <ejs-uploader
                                                #defaultupload
                                                [autoUpload]='true'
                                                (selected)="upload($event, data)"
                                                [buttons]="buttons"
                                                multiple='false'
                                                class="hrep-file-upload">
                                                <ng-template #template let-data="">
                                                    <span class='file-name'>{{data.name}}</span>
                                                </ng-template>
                                            </ejs-uploader>
                                        </div>
                                      <button ejs-button cssClass="hrep-icon-btn" *ngIf="data.file"
                                              iconCss="icon-cloud-download"
                                              (click)="downloadAttachment($event, data.file)"
                                      ></button>
                                      </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
</div>
