import {Component, ElementRef, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {EmitType} from '@syncfusion/ej2-base';
import {CrudService} from '../../../../core/services/crud.service';
import {VacationRequest} from '../../../../core/models/vacation-request';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-vacation-overview',
  templateUrl: './vacation-overview.component.html',
  styleUrls: ['./vacation-overview.component.scss']
})
export class VacationOverviewComponent implements OnInit {
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  @ViewChild('container', { read: ElementRef }) container: ElementRef | undefined;
  @ViewChild('grid_feature_vacations') public grid_feature_vacations: GridComponent | undefined;
  public holidays: VacationRequest[] = [];
  public absenceTimeCompensations: VacationRequest[] = [];
  public leasedEmployeeAbsenceGenerals: VacationRequest[] = [];
  public current_user: User | null = null;
  public isOpened = false;
  public targetElement: HTMLElement | undefined;
  constructor(
      private crudService: CrudService, private cd: ChangeDetectorRef, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      const paramsFeatureType = this.buildParams(this.current_user, 'holiday');
      const paramsPresentType = this.buildParams(this.current_user, 'overtime');
      const paramsPastType = this.buildParams(this.current_user, 'missed_time');
      this.fetchVacations(paramsFeatureType, paramsPresentType, paramsPastType);
      this.cd.detectChanges();
    });
    this.initializeTarget();
  }

  public buildParams(currentUser: User, vacationType: string): { [key: string]: string } {
    return  {
      include: 'file' + (vacationType === 'missed_time' ? ',absence_type' : ''),
      'filter[user_id]': this.current_user?.id === undefined ? '' : this.current_user?.id,
      'sort':'-valid_from',
    };
  }

  public fetchVacations(
      paramsFeatureType: { [key: string]: string },
      paramsPresentType: { [key: string]: string },
      paramsPastType: { [key: string]: string }
  ): void {
    this.crudService.fetchEntities<VacationRequest>('vacation_request', paramsFeatureType).subscribe(response => {
          this.holidays = response.data;
          this.cd.detectChanges();
        }
    );
    this.crudService.fetchEntities<VacationRequest>('absence_time_compensation', paramsPresentType).subscribe(response => {
          this.absenceTimeCompensations = response.data;
          this.cd.detectChanges();
        }
    );
    this.crudService.fetchEntities<VacationRequest>('leased_employee_absence_general', paramsPastType).subscribe(response => {
          this.leasedEmployeeAbsenceGenerals = response.data;
          this.cd.detectChanges();
        }
    );
  }
  public onRequestVacation = (): void => {
    this.isOpened = true;
    this.ejDialog?.show();
  };

  public initializeTarget: EmitType<any> = () => {
    this.targetElement = document.body;
  };

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }

  public onVacationRequestSent(value: boolean){
    this.isOpened = value;
    this.ejDialog?.hide();
    this.ngOnInit();
  }

  public downloadAttachment(event: Event, fileUrl: string, fileName: string) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = fileUrl;
    a.download = fileName;
    a.target = '_blank';
    console.log(a);
    a.click();
  }

}
