<div class='content'>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="hrep-dv-number">
                    <span class="pe-2 me-1">DV-Nr.:</span>
                    <span>{{employmentData?.contract_number}}</span>
                </div>
            </div>
            <div class="col-12">
                <hr class="hrep-hr">
            </div>
            <div class="col-12">
                <div class="hrep-accordion accordion-light text-start">
                    <ejs-accordion>
                        <e-accordionitems>
                            <e-accordionitem expanded='true'>
                                <ng-template #header>
                                    <div>{{ 'CONTRACT_EMPLOYMENTS.CONTRACT_EMPLOYMENT_DATA' | translate }}</div>
                                </ng-template>
                                <ng-template #content>
                                    <div class="row pb-5">
                                        <div class="col-12 col-lg-6">
                                            <div class="row">
                                                <div class="col-9 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.EMPLOYMENT_RELATIONSHIP' | translate }}</label>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.employment_type"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.EMPLOYMENT_TYPE' | translate }}</label>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.employment_mode"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.SALARY_CLASS' | translate }}</label>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.salary_classification"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                                <div class="col-6 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'COMMON.VALID_FROM' | translate }}:</label>
                                                    <div class='textboxes'>
                                                        <ejs-datepicker [readonly]="true" [value]="getDate(employmentData?.valid_from)" format='dd.MM.yyyy'
                                                                     cssClass="hrep-input hrep-datepicker no-calendar-icon"></ejs-datepicker>
                                                    </div>
                                                </div>
                                                <div class="col-6 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'COMMON.VALID_TO' | translate }}:</label>
                                                    <div class='textboxes'>
                                                        <ejs-datepicker [readonly]="true" [value]="getDate(employmentData?.valid_to)" format='dd.MM.yyyy'
                                                                     cssClass="hrep-input hrep-datepicker no-calendar-icon"></ejs-datepicker>
                                                    </div>
                                                </div>
                                                <div class="col-6 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.ENTRY' | translate }}:</label>
                                                    <div class='textboxes'>
                                                        <ejs-datepicker [readonly]="true" [value]="getDate(employmentData?.contract_entry_date)" format='dd.MM.yyyy'
                                                                     cssClass="hrep-input hrep-datepicker no-calendar-icon"></ejs-datepicker>
                                                    </div>
                                                </div>
                                                <div class="col-6 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.EXIT' | translate }}:</label>
                                                    <div class='textboxes'>
                                                        <ejs-datepicker [readonly]="true" [value]="getDate(employmentData?.exit_action_date)" format='dd.MM.yyyy'
                                                                     cssClass="hrep-input hrep-datepicker no-calendar-icon"></ejs-datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="row">
                                                <div class="col-9 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.COLLECTIVE_AGREEMENT' | translate }}</label>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.collective_agreement"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'COMMON.HOURS' | translate }}</label>
                                                    <ejs-numerictextbox [readonly]="true"
                                                                        format='n'
                                                                        [showSpinButton]='false'
                                                                        [value]="employmentData?.employment_hours"
                                                                        width="50"
                                                                        cssClass="hrep-input">
                                                    </ejs-numerictextbox>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.employment_hours_unit"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                                <div class="col-8 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.YEARS_EXPERIENCE' | translate }}</label>
                                                    <ejs-dropdownlist [readonly]="true" id='lohn' cssClass="hrep-dropdown"
                                                                      [value]="employmentData?.wage_group_years" [text]="'' + employmentData?.wage_group_years" width="70"></ejs-dropdownlist>
                                                </div>
                                                <div class="col-4 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.SALARY' | translate }}</label>
                                                    <ejs-numerictextbox [readonly]="true"
                                                                        format='n'
                                                                        [showSpinButton]='false'
                                                                        [value]="employmentData?.rewarding_salary"
                                                                        width="50"
                                                                        cssClass="hrep-input">
                                                    </ejs-numerictextbox>
                                                </div>
                                                <div class="col-8 d-flex justify-content-between align-items-center mb-3">
                                                    <label>{{ 'CONTRACT_EMPLOYMENTS.EXIT_REASON' | translate }}</label>
                                                    <div class='textboxes'>
                                                        <ejs-textbox [readonly]="true" [value]="employmentData?.exit_reason_name"
                                                                     cssClass="hrep-input"></ejs-textbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-accordionitem>
                        </e-accordionitems>
                    </ejs-accordion>
                </div>
            </div>
        </div>
    </div>
</div>

