<div class='content'>
    <div class="container">
        <div class="row">
            <div *ngIf="!isSubmitted && !isWrong">
                <h2 class="text-uppercase">Would you really like to sign?</h2>
                <p class="fw-bold">please enter your password</p>
                <form [formGroup]="form" (ngSubmit)="doSign()" class="form form-login col-8 offset-2">
                    <div class="form-field">
                        <input id="password" type="password" class="e-input no-side-borders" formControlName="password"
                               placeholder="Password eingeben..." required>
                    </div>

                    <div class="form-field">
                        <button cssClass="e-small" ejs-button type="submit" class="e-btn e-primary">Passwort Andern
                        </button>
                    </div>
                </form>
            </div>
            <div *ngIf="isSubmitted">
                <h2 class="text-uppercase">Successfully signed!</h2>
                <div class="d-flex justify-content-center">
                    <div class="hrep-squared-icon">
                        <span class="e-icons e-icon-tick"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="isWrong">
                <h2 class="text-uppercase">Wrong password!</h2>
                <div class="d-flex justify-content-center">
                    <div class="hrep-squared-icon">
                        <span class="e-input-group-icon icon-exclamation"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
