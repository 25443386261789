<button ejs-button iconCss="icon-logout" [isPrimary]="true" class="mb-2" content="{{ 'COMMON.LOGOUT' | translate }}" routerLink="/logout"></button>
<app-search-box></app-search-box>
<div class="hrep-divider"></div>
<ejs-listview id='listview' [dataSource]='links' cssClass="e-list-template hrep-list-link">
    <ng-template #template let-link="">
        <div class="e-list-wrapper">
            <a routerLink="{{link.route}}" class="d-flex justify-content-between align-items-center text-decoration-none">
                <span class="e-list-icon icon-big icon-{{link.icon}}"></span>
                <div class="d-flex w-100">
                    <div class="position-relative">
                        <div class="e-list-item-content">{{link.title}}</div>
                        <span *ngIf="link.hasNotification" class="e-badge e-badge-danger e-badge-notification e-badge-circle">{{dataSource.length}}</span>
                    </div>
                </div>
                <span class="icon-arrow-{{link.arrowIcon}} float-end"></span>
            </a>
        </div>
    </ng-template>
</ejs-listview>
<div class="hrep-list">
    <ejs-listview
            id="listview_template"
            cssClass="e-list-template"
            [dataSource]="dataSource">
        <ng-template #template let-menuItem="">
            <div class="e-list-wrapper" (click)="openMessage(menuItem.id)">
                <div class="left-section" *ngIf="menuItem.created_at !== ''">
                    <span>{{menuItem.created_at | date}}</span>
                </div>
                <div class="main-section">
                    <span class="e-list-item-header hrep-txt-primary">{{menuItem.title}}</span>
                </div>
                <div class="right-section">
                    <button ejs-button cssClass="hrep-icon-btn"
                            iconCss="icon-action-undo" title="Reply"></button>
                    <button ejs-button cssClass="hrep-icon-btn" *ngIf="menuItem.attachment"
                            iconCss="icon-paper-clip" title="Attachment" (click)="downloadAttachment(menuItem)"></button>
                    <button ejs-button cssClass="hrep-icon-btn" *ngIf="menuItem.is_birthday"
                            iconCss="icon-present" title="Birthday"></button>
                    <span *ngIf="menuItem.is_read === 0"
                          class="e-badge e-badge-dot e-badge-circle e-badge-success"></span>
                </div>
            </div>
        </ng-template>
    </ejs-listview>
</div>
