import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {Route} from '../../../core/enums/route.enum';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {

  public constructor(
    private router: Router,
    private authentication: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.authentication.isLoggedIn()) {
      this.authentication.logout();
      this.router.navigate([Route.LOGIN]).then();
    }
  }

}
