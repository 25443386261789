import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {throwError} from 'rxjs';
import {Route} from '../../../core/enums/route.enum';
import {catchError} from 'rxjs/operators';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import {ResetPasswordRequestComponent} from '../reset-password-request-modal/reset-password-request.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {

  public isOpened = false;
  public form: FormGroup;
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  @ViewChild(ResetPasswordRequestComponent) resetPasswordComponent: ResetPasswordRequestComponent | undefined;
  @ViewChild('container', { read: ElementRef }) container: ElementRef | undefined;
  public targetElement: HTMLElement | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authentication: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
    ) {

    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    if (this.authentication.isLoggedIn()) {
      this.router.navigate([Route.BASE]).then();
    }
    this.initializeTarget();
  }

  ngOnDestroy(): void {
  }

  public initializeTarget: EmitType<any> = () => {
    this.targetElement = this.container?.nativeElement.parentElement;
  };

  public onOpenDialog = (): void => {
    this.isOpened = true;
    this.ejDialog?.show();
  };

  public doLogin(): void {
    const formValues = this.form.value;

    this.form.setErrors(null);

    this.authentication
      .login(formValues.email, formValues.password)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          this.onLoginError(response);

          return throwError(response);
        }))
      .subscribe((response: any) => {
        this.onLoginSuccess(response);
      });
  }

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }

  private onLoginError(response: HttpErrorResponse): void {
    this.form.setErrors({
      loginError: response.error['hydra:description'] || this.translate.instant('LOGIN.WRONG_CREDENTIALS')
    });

    this.cdr.detectChanges();
  }

  private onLoginSuccess(response: any): void {
    this.router.navigate([Route.BASE]).then();
  }

}
