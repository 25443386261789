<div class="col-12 hrep-headline d-flex align-items-center mb-4">
    <i class="icon-doc me-2"></i>
    <h6 class="small fw-bold mb-0">{{ 'COMMON.VACATION_REQUEST' | translate }}</h6>
</div>
<hr class="hrep-hr">
<div class="hrep-accordion accordion-light">
    <ejs-accordion>
        <e-accordionitems>
            <e-accordionitem expanded='true'>
                <ng-template #header>
                    <div>{{ 'VACATION.TYPE_HOLIDAY' | translate }}</div>
                </ng-template>
                <ng-template #content>
                    <ejs-grid #grid_feature_vacations id="grid_feature_vacations" class="hrep-grid" [dataSource]='holidays' [enableHover]='false'
                              [rowHeight]='30' gridLines='Both'>
                        <e-columns>
                            <e-column field='valid_from' headerText="{{ 'COMMON.FROM' | translate }}:" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='valid_to' headerText="{{ 'COMMON.TO' | translate }}:" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='calculated_days' headerText="{{ 'COMMON.DAYS' | translate }}" textAlign='Center' format='N'
                                      width='50'></e-column>
                            <e-column field='calculated_hours' headerText="{{ 'COMMON.HOURS' | translate }}" textAlign='Center' format='N'
                                      width=80></e-column>
                            <e-column field='is_confirmed' headerText="{{ 'COMMON.STATUS' | translate }}" textAlign='Center' width=80>
                                <ng-template #template let-vacationData>{{ vacationData.is_confirmed ? 'genehmigt' : 'offen'}}
                                </ng-template>
                            </e-column>
                            <e-column field='statusIsOpen' headerText="{{ 'COMMON.DOCUMENT' | translate }}" width='80' textAlign='Center'>
                                <ng-template #template let-vacationData>
                                    <button ejs-button class="hrep-icon-btn" *ngIf="vacationData.file" (click)="downloadAttachment($event, vacationData.file.file_url, vacationData.file.file_name)">
                                        <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                    </button>
                                    <div *ngIf="vacationData.file && vacationData.file.has_to_be_signed && !vacationData.file.signed_at"
                                         class="e-badge e-badge-danger e-badge-circle hrep-fill-icon">
                                      <span class="icon-exclamation"></span>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                    <div>
                        <button (click)="onRequestVacation()" ejs-button cssClass="e-small e-primary"
                                iconCss="icon-plus" content="{{ 'VACATION.ADD_NEW_REQUEST' | translate }}"></button>
                    </div>
                </ng-template>
            </e-accordionitem>
            <e-accordionitem expanded='true'>
                <ng-template #header>
                    <div>{{ 'VACATION.TYPE_OVERTIME' | translate }}</div>
                </ng-template>
                <ng-template #content>
                    <ejs-grid id="grid_present_vacations" class="hrep-grid" [dataSource]='absenceTimeCompensations' [enableHover]='false'
                              [rowHeight]='30' gridLines='Both'>
                        <e-columns>
                            <e-column field='valid_from' headerText="{{ 'COMMON.FROM' | translate }}:" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='valid_to' headerText="{{ 'COMMON.TO' | translate }}:" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='calculated_days' headerText="{{ 'COMMON.DAYS' | translate }}" textAlign='Center' format='N'
                                      width='50'></e-column>
                            <e-column field='calculated_hours' headerText="{{ 'COMMON.HOURS' | translate }}" textAlign='Center' format='N'
                                      width=80></e-column>
                            <e-column field='is_confirmed' headerText="{{ 'COMMON.STATUS' | translate }}" textAlign='Center' width=80>
                                <ng-template #template let-vacationData>{{ vacationData.is_confirmed ? 'genehmigt' : 'offen'}}</ng-template>
                            </e-column>
                            <e-column field='statusIsOpen' headerText="{{ 'COMMON.DOCUMENT' | translate }}" width='80' textAlign='Center'>
                                <ng-template #template let-vacationData>
                                    <button ejs-button class="hrep-icon-btn" *ngIf="vacationData.file" (click)="downloadAttachment($event, vacationData.file.file_url, vacationData.file.file_name)">
                                        <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                    </button>
                                    <div *ngIf="vacationData.file && vacationData.file.has_to_be_signed && !vacationData.file.signed_at"
                                         class="e-badge e-badge-danger e-badge-circle hrep-fill-icon">
                                      <span class="icon-exclamation"></span>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </ng-template>
            </e-accordionitem>
            <e-accordionitem expanded='true'>
                <ng-template #header>
                    <div>{{ 'VACATION.TYPE_MISSED_TIME' | translate }}</div>
                </ng-template>
                <ng-template #content>
                    <ejs-grid id="grid_past_vacations" class="hrep-grid" [dataSource]='leasedEmployeeAbsenceGenerals' [enableHover]='false'
                              [rowHeight]='30' gridLines='Both'>
                        <e-columns>
                            <e-column field='valid_from' headerText="{{ 'COMMON.FROM' | translate }}" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='valid_to' headerText="{{ 'COMMON.TO' | translate }}" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                      width='100'></e-column>
                            <e-column field='absence_type.name' headerText="Fehlzeitenart" textAlign='Center' width='80'></e-column>
                            <e-column field='calculated_days' headerText="{{ 'COMMON.DAYS' | translate }}" textAlign='Center' format='N'
                                      width='50'></e-column>
                            <e-column field='calculated_hours' headerText="{{ 'COMMON.HOURS' | translate }}" textAlign='Center' format='N'
                                      width=60></e-column>
                            <e-column field='is_confirmed' headerText="{{ 'COMMON.STATUS' | translate }}" textAlign='Center' width=60>
                                <ng-template #template let-vacationData>{{ vacationData.is_confirmed ? 'genehmigt' : 'offen'}}</ng-template>
                            </e-column>
                            <e-column field='statusIsOpen' headerText="{{ 'COMMON.DOCUMENT' | translate }}" width='80' textAlign='Center'>
                                <ng-template #template let-vacationData>
                                    <button ejs-button class="hrep-icon-btn" *ngIf="vacationData.file" (click)="downloadAttachment($event, vacationData.file.file_url, vacationData.file.file_name)">
                                        <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                    </button>
                                    <div *ngIf="vacationData.file && vacationData.file.has_to_be_signed && !vacationData.file.signed_at"
                                         class="e-badge e-badge-danger e-badge-circle hrep-fill-icon">
                                      <span class="icon-exclamation"></span>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </ng-template>
            </e-accordionitem>
        </e-accordionitems>
    </ejs-accordion>
</div>

<ejs-dialog id='dialog' #ejDialog header="{{ 'VACATION.REQUEST_FOR_LEAVE' | translate }}" showCloseIcon='true' [visible]="false"
            (beforeOpen)="beforeOpen($event)" (beforeClose)="beforeClose($event)"
            [target]='targetElement' width='700px' cssClass="hrep-primary-dialog">
    <ng-template #content>
        <app-vacation-request-modal [currentUser]="current_user" *ngIf="isOpened" (vacationRequestSent)="onVacationRequestSent($event)"></app-vacation-request-modal>
    </ng-template>
</ejs-dialog>
