import {ChangeDetectionStrategy, Component, OnInit, Input} from '@angular/core';
import {ContractOfEmployment} from '../../../../core/models/contract-of-employment';

@Component({
  selector: 'app-contract-employment-modal',
  templateUrl: './contract-employment-modal.component.html',
  styleUrls: ['./contract-employment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractEmploymentModalComponent implements OnInit {
  @Input() employmentData?: ContractOfEmployment;

  constructor() { }

  ngOnInit(): void {
  }

  public getDate(date?: string): Date|null {
    return date ? new Date(date) : null;
  }
}
