import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EmitType, Internationalization} from '@syncfusion/ej2-base';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ContractEmploymentModalComponent} from '../contract-employment-modal/contract-employment-modal.component';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import {CrudService} from '../../../../core/services/crud.service';
import {ContractOfEmployment} from '../../../../core/models/contract-of-employment';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Assignment} from '../../../../core/models/assignment';

const instance: Internationalization = new Internationalization();

@Component({
  selector: 'app-contract-employments',
  templateUrl: './contract-employments.component.html',
  styleUrls: ['./contract-employments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractEmploymentsComponent implements OnInit {
  public contractEmployments: ContractOfEmployment[] = [];
  public assignments: Assignment[] = [];
  public contractEmployment: ContractOfEmployment = {};

  public isOpened = false;
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  @ViewChild(ContractEmploymentModalComponent) contractEmploymentModal: ContractEmploymentModalComponent | undefined;
  @ViewChild('container', { read: ElementRef }) container: ElementRef | undefined;
  public targetElement: HTMLElement | undefined;
  public modalTitle = '';
  @ViewChild('grid') public grid: GridComponent | undefined;

  constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    ) { }

  ngOnInit(): void {
    this.crudService.fetchEntities<ContractOfEmployment>('contract_of_employment', {'filter[user_id]': this.authService.getUser(), 'sort':'-valid_from', include:'file'}).subscribe(response => {
          this.contractEmployments = response.data;
          this.cd.detectChanges();
        }
    );
    this.crudService.fetchEntities<Assignment>('assignment', {'sort':'-valid_from', 'filter[user_id]': this.authService.getUser()}).subscribe(response => {
          this.assignments = response.data;
          this.cd.detectChanges();
        }
    );
    this.initializeTarget();
  }

  public initializeTarget: EmitType<any> = () => {
    this.targetElement = this.container?.nativeElement.parentElement;
  };

  public onOpenDialog = (data: any): void => {
    this.contractEmployment = data;
    this.isOpened = true;
    this.ejDialog?.show();
  };

  public onOpenAssignmentDialog = (data: any): void => {
    console.log(data);
  };

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }

  public downloadAttachment(event: Event, item: any) {
    console.log(item);
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = item.file.file_url; //Image Base64 Goes here
    a.download = item.file.file_name; //File name Here
    a.target = '_blank';
    a.click();
  }
}
