import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit, ChangeDetectorRef,
} from '@angular/core';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'xcentric-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements AfterViewInit {
  visible = false;
  title = 'Success';
  content = 'Success';

  constructor(
      protected toastService: ToastService,
      private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.toastService.toastComponent = this;
    console.log(this)
  }

  show(toastModel: any): void {
    this.title = toastModel.title;
    this.content = toastModel.content;
    this.visible = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.visible = false;
      this.cdr.detectChanges();
    }, 3000);
  }
}
