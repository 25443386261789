import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private expendedMessage: string = '';
  private expendedMessageChanged = new Subject<any>();

  public constructor(
      private http: HttpClient,
  ) {
  }

  expendedMessageChanged$ = this.expendedMessageChanged.asObservable();

  public expandMessage(message_id: string) {
    this.expendedMessage = message_id
    this.expendedMessageChanged.next({
      message_id: message_id
    });
    return this;
  }

  public getExpendedMessage(): string
  {
    return this.expendedMessage;
  }

  public signMessage(password: string | null | undefined, id: string | null | undefined): Observable<any> {
    return this.http.post(environment.ENTITY_MANAGER_URL_PREFIX + 'file/sign/' + id, { password });
  }
}
