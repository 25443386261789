import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent implements OnInit {

  public value = '';

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public search(event: KeyboardEvent|null = null): void {
    if (event instanceof KeyboardEvent) {
      this.value = (event.target as HTMLInputElement).value;
    }
    this.router.navigate(['/search-list/' + this.value ]);
  }
}
