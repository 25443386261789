<div class="col-12 hrep-headline d-flex align-items-center">
    <i class="icon-docs me-2"></i>
    <h6 class="small fw-bold mb-0">Dokumente</h6>
</div>
<hr class="hrep-hr mb-0">
<ejs-listview id='listview' [dataSource]='documentTypes' cssClass="e-list-template hrep-list-link">
    <ng-template #template let-documentType="">
        <div class="e-list-wrapper">
            <a (click)="openDocuments(documentType.id)" class="d-flex justify-content-between align-items-center text-decoration-none">
                <span class="e-list-icon icon-{{documentType.icon_path}}"></span>
                <div class="e-list-item-content">{{documentType.name}}</div>
                <span class="icon-arrow-right float-end"></span>
            </a>
        </div>
    </ng-template>
</ejs-listview>

