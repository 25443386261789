import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hr-employee-portal-frontend';
  loaded$ = this.translate.getTranslation('rs')

  constructor(
    protected translate: TranslateService
  ) {
    this.translate.setDefaultLang('rs');
    this.translate.use('rs');
  }
}
