<div class="col-12 hrep-headline d-flex align-items-center">
    <i class="icon-docs me-2"></i>
    <h6 class="small fw-bold mb-0">Dokumente</h6>
</div>
<hr class="hrep-hr mb-0">
<ejs-listview id='listview' [dataSource]='documents' cssClass="e-list-template hrep-list-link">
    <ng-template #template let-document="">
      <div class="e-list-wrapper e-list-multi-line">
        <div class="left-section icon-section">
          <span>
              <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
          </span>
        </div>
        <div class="main-section">
          <span class="e-list-item-header hrep-txt-primary">{{document.name}}</span>
          <span class="e-list-content">{{document.created_at | date}}</span>
        </div>
        <div class="right-section">
          <button ejs-button cssClass="hrep-icon-btn" (click)="downloadAttachment($event, document)"><img
            src="../../../../../assets/layout/images/pdf-download-icon.png" alt=""/>
          </button>
          <button ejs-button cssClass="hrep-icon-btn"
                  iconCss="icon-cloud-download"></button>
        </div>
      </div>
      <div class="hrep-divider"></div>
    </ng-template>
</ejs-listview>

