import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Message} from '../../../../core/models/message';
import {User} from '../../../../core/models/user';
import {MessagesService} from '../../../../core/services/messages.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightSidebarComponent {

  public current_user: User | null = null;
  dataSource: any[] = [];
  links: {title: string; icon: string; arrowIcon: string; route: string; hasNotification: boolean}[] = [
    {
      title: 'Hilfe',
      icon: 'question',
      arrowIcon: 'right',
      route: 'faq',
      hasNotification: false
    },
    {
      title: 'Profil',
      icon: 'user',
      arrowIcon: 'right',
      route: 'profile',
      hasNotification: false
    },
    {
      title: 'Mitteilungen',
      icon: 'envelope-open',
      arrowIcon: 'down',
      route: 'messages',
      hasNotification: true
    },
  ];
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    private messageSelectionService: MessagesService,
    ) {
    this.form = this.fb.group({
      search: this.fb.control('')
    });

    this.messageSelectionService.expendedMessageChanged$.subscribe((selection) => {
      const paramsRead = this.buildParams();
      this.fetchMessages(paramsRead);
    });
  }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      const paramsRead = this.buildParams();
      this.fetchMessages(paramsRead);
      this.cd.detectChanges();
    });
  }

  public search(): void {
    this.router.navigate(['/search-list/' + this.form.get('search')?.value ]);
    this.form.reset();
  }

  public buildParams(): { [key: string]: string } {
    return  {
      include: 'user',
      'filter[user_id]': this.current_user?.id === undefined ? '' : this.current_user?.id,
      'filter[is_read]': '0',
      'per_page': '5'
    };
  }

  public fetchMessages(paramsRead: { [key: string]: string }): void {
    this.crudService.fetchEntities<Message>('message', paramsRead).subscribe(response => {
      this.dataSource = response.data;
      this.cd.detectChanges();
    });
  }

  public downloadAttachment(item: any) {
    const a = document.createElement("a"); //Create <a>
    a.href = item.attachment; //Image Base64 Goes here
    a.download = item.attachment_name; //File name Here
    a.click();
  }

  public openMessage(message_id: string) {
    this.messageSelectionService.expandMessage(message_id);
    this.router.navigate(['messages']);
  }
}
