import {ChangeDetectorRef, Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudService} from '../../../../core/services/crud.service';
import {User} from '../../../../core/models/user';
import {VacationRequestType} from '../../../../core/models/vacation-request-type';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-vacation-request-modal',
  templateUrl: './vacation-request-modal.component.html',
  styleUrls: ['./vacation-request-modal.component.scss']
})
export class VacationRequestModalComponent implements OnInit {
 @Input() currentUser: User | null = null;
 @Input() vacationType: VacationRequestType | null = null;
 @Output() public vacationRequestSent = new EventEmitter<boolean>();
  public form: FormGroup;
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public date: number = new Date().getDate();
  public minStartDate: Date = new Date(this.fullYear, this.month , this.date);
  public minEndDate: any;
  public minEndDateValue: any;
  public endDateDisabled = false;
  public userFullName = '';
  public vacationRequestStatus = '';
  public submitInProgress = false;

    constructor(
        private crudService: CrudService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        this.form = this.fb.group({
            user: this.fb.control(this.currentUser),
            from: this.fb.control('', [Validators.required]),
            to: this.fb.control('', [Validators.required]),
            approval_state: this.fb.control(''),
        });
    }

  ngOnInit(): void {
    this.minEndDate = this.minStartDate;
    this.userFullName = this.currentUser?.first_name + ' ' + this.currentUser?.last_name;
    this.vacationRequestStatus = 'offen';
  }
  public doVacationRequest(): void {
    const formValues = this.form.value;
    this.form.setErrors(null);
    this.submitInProgress = true;
    this.crudService.createEntity('vacation_request', {
        valid_from: formValues.from.toString().slice(0, 24),
        valid_to: formValues.to.toString().slice(0, 24),
        approval_state: 'requested',
        user: this.currentUser
    })
        .pipe(
            catchError((response: HttpErrorResponse) => {
                this.onVacationError(response);

                return throwError(response);
            }))
        .subscribe((response: any) => {
            this.onVacationSuccess(response);
        });
  }
  public disabledDateWeekends(args: any): void {
    if (args.date.getDay() === 0 || args.date.getDay() === 6) {
      args.isDisabled = true;
    }
  }

  public onStartDateChange(args: any): void {
    this.endDateDisabled = args.value !== null;
    if(args.value > this.minEndDate) {
      this.minEndDate = args.value;
      this.minEndDateValue = args.value;
    } else if (args.value === null) {
      this.minEndDate = this.minStartDate;
      this.minEndDateValue = null;
    } else {
      this.minEndDate = this.minStartDate;
      this.minEndDateValue = this.minStartDate;
    }
  }

    private onVacationError(response: HttpErrorResponse): void {
        this.form.setErrors({
            loginError: response.error['hydra:description']
        });
        this.cdr.detectChanges();
    }

    private onVacationSuccess(response: any): void {
        this.vacationRequestSent.emit(true);
    }

}
