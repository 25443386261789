<div class="row">
    <div class="col-1 list-left-section pe-0">
        <span *ngIf="listItem.created_at !== ''">{{listItem.created_at | date}}</span>
    </div>
    <div class="col-9 list-main-section">
        <span class="e-list-item-header hrep-txt-primary">{{listItem.title}}</span>
        <span [ngClass]="{'truncated':!_isExpanded}" class="e-list-content e-text-overflow pt-2"
              [innerHTML]="listItem.content"></span>
        <div *ngIf="listItem.file && _isExpanded" class="hrep-message-attachments">
            <button ejs-button class="hrep-icon-btn p-0 me-2" (click)="downloadAttachment($event)">
                <img class="img-fluid" src="../../../../../../assets/layout/images/pdf-icon.png" alt=""/>
            </button>
        </div>
        <button (click)="onShowReplyBox($event)" *ngIf="_isExpanded" ejs-button iconCss="icon-action-undo" cssClass="e-primary e-small mt-2"
                content="Antworten"></button>
        <button (click)="onOpenDialog()" *ngIf="_isExpanded && listItem.file && listItem.file.has_to_be_signed" ejs-button cssClass="e-primary e-small mt-2"
                content="Unterschreiben"></button>
        <div *ngIf="showReplyBox" class="message-response-block mt-1">
            <ejs-textbox [multiline]="true" class="hrep-input" [(value)]="replayText"></ejs-textbox>
            <button ejs-button iconCss="icon-action-undo" cssClass="e-primary e-small float-end" (click)="sendReplay($event)"
                    content="Senden"></button>
        </div>
    </div>
    <div class="col-2 text-end list-right-section">
        <div class="d-inline-block pe-3">
            <button ejs-button cssClass="hrep-icon-btn" *ngIf="listItem.file" (click)="downloadAttachment($event)"
                    iconCss="icon-paper-clip" title="Attachment"></button>
            <button ejs-button cssClass="hrep-icon-btn" (click)="onShowReplyBox($event)" *ngIf="!_isExpanded"
                    iconCss="icon-action-undo" title="Reply"></button>
        </div>
        <span *ngIf="!listItem?.is_read"
              class="e-badge e-badge-dot e-badge-circle e-badge-success"></span>
        <button *ngIf="listItem?.is_read" ejs-button cssClass="hrep-icon-btn"
                iconCss="e-icons e-icon-tick"></button>
    </div>
</div>

<ejs-dialog id='dialog' #ejDialog  header='Dialog' showCloseIcon='true' [visible]="false" (beforeOpen)="beforeOpen($event)" (beforeClose)="beforeClose($event)"
            [target]='targetElement' width='500px'> <ng-template #content> <app-signature-dialog *ngIf="isOpened" [message]="listItem"></app-signature-dialog></ng-template></ejs-dialog>
