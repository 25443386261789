import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {DocumentType} from '../../../../core/models/document-type';
import {Router} from '@angular/router';

@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTypesComponent implements OnInit {

  public documentTypes: DocumentType[] = [];

  constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private router: Router,
    ) {
  }

  ngOnInit(): void {
    this.crudService.fetchEntities<DocumentType>('document_type').subscribe(response => {
        this.documentTypes = response.data;
        this.cd.detectChanges();
      }
    );
  }

  public openDocuments(documentTypeId: string) {
    this.router.navigate(['documents/' + documentTypeId])
  }
}
