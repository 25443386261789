import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {User} from '../../../../core/models/user';
import {FileUploadService} from '../../../../core/services/file-upload.service';
import {DocumentUpload} from '../../../../core/models/document-upload';
import {Document} from '../../../../core/models/document';
import {DOCUMENT} from '@angular/common';
import {SelectedEventArgs} from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsUploadComponent implements OnInit {
  public documents: Document[] = [];
  public buttons: any = {
    browse: ''
  };
  public currentUser: User | null = null;

  constructor(private crudService: CrudService,
              private authService: AuthenticationService,
              private cd: ChangeDetectorRef,
              private fileUploadService: FileUploadService,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.currentUser = user;
      const params = this.buildParams();
      this.fetchDocuments(params);
      this.cd.detectChanges();
    });
  }

  public buildParams(): { [key: string]: string } {
    return {
      include: 'user,file',
      'filter[user_id]': this.currentUser?.id === undefined ? '' : this.currentUser?.id,
    };
  }

  public fetchDocuments(params: { [key: string]: string }): void {
    this.crudService.fetchEntities<DocumentUpload>('document-upload', params).subscribe(response => {
      this.documents = response.data;
      this.cd.detectChanges();
    });
  }

  upload(event: SelectedEventArgs, data: any) {
    this.fileUploadService.upload(event.filesData[0].rawFile).subscribe(response => {
      const payload = {file: response.data, id: data.id, name: data.name, user: data.user};
      this.crudService.updateEntity<DocumentUpload>('document-upload', data.id ?? '', payload)
          .subscribe(() => {
        this.fetchDocuments(this.buildParams());
      });
    });
  }

  onClickUpload(event: any) {
    event.target.parentNode.parentNode.parentNode.querySelector('#dropArea').getElementsByTagName('button')[0].click();
  }
}
