import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {Faq} from '../../../../core/models/faq';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FAQComponent implements OnInit{
  public faqQuestions: Faq[] = [];

  constructor(private crudService: CrudService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.crudService.fetchEntities<Faq>('frequently_asked_question').subscribe(response => {
          this.faqQuestions = response.data;
          this.cd.detectChanges();
        }
    );
  }
}
