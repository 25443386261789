<div class='content'>
    <div class="container">
        <div class="row">
            <div *ngIf="!isSubmitted">
                <h2 class="text-uppercase">Passwort vergessen?</h2>
                <p class="fw-bold">Sie haben Ihr Passwort vergessen? Das ist kein Problem. Bitte geben Sie Ihre
                    E-Mail-Adresse ein und
                    klicken Sie auf "Passwort Andern".</p>
                <form [formGroup]="form" (ngSubmit)="doReset()" class="form form-login col-8 offset-2">
                    <div class="form-field">
                        <input id="email" type="email" class="e-input no-side-borders" formControlName="email"
                               placeholder="E-mail Adresse eingeben..." required>
                    </div>

                    <div class="form-field">
                        <button cssClass="e-small" ejs-button type="submit" class="e-btn e-primary">Passwort Andern
                        </button>
                    </div>
                </form>
            </div>
            <div *ngIf="isSubmitted">
                <h2 class="text-uppercase">Passwort versendet!</h2>
                <p class="fw-bold">Ein Link wunde an live E-Mail-Aresse versendet</p>
                <div class="d-flex justify-content-center">
                    <div class="hrep-squared-icon">
                        <span class="e-icons e-icon-tick"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
