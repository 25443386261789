import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {Message} from '../../../../core/models/message';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {MessagesService} from '../../../../core/services/messages.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesComponent implements OnInit {

  public readMessages: Message[] = [];
  public unreadMessages: Message[] = [];
  public current_user: User | null = null;
  public expendedMessage: string|null = null;

  public constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    private messageSelectionService: MessagesService
  ) {
    this.messageSelectionService.expendedMessageChanged$.subscribe((selection) => {
      this.doOpenMessage(selection.message_id);
    });
    this.expendedMessage = this.messageSelectionService.getExpendedMessage();
  }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      const paramsUnread = this.buildParams(false);
      const paramsRead = this.buildParams(true);
      this.fetchMessages(paramsRead, paramsUnread);
      this.cd.detectChanges();
    });
  }

  public buildParams(isRead: boolean): { [key: string]: string } {
    return  {
      include: 'user,file',
      'filter[user_id]': this.current_user?.id === undefined ? '' : this.current_user?.id,
      'filter[is_read]': isRead ? '1' : '0'
    };
  }

  public fetchMessages(paramsRead: { [key: string]: string }, paramsUnread: { [key: string]: string }): void {
    this.crudService.fetchEntities<Message>('message', paramsRead).subscribe(response => {
      this.readMessages = response.data;
      this.cd.detectChanges();
    });
    this.crudService.fetchEntities<Message>('message', paramsUnread).subscribe(response => {
      this.unreadMessages = response.data;
      this.cd.detectChanges();
    });
  }

  public onOpenMessage(message_id: string) {
    this.messageSelectionService.expandMessage(message_id);
  }

  public doOpenMessage(message_id: string) {
    const previousMessageIndex = this.unreadMessages.findIndex((aMessage) => {
      return aMessage.id === this.expendedMessage;
    });
    if (previousMessageIndex !== -1) {
      const previousMessage = this.unreadMessages.splice(previousMessageIndex, 1)[0];
      this.unreadMessages = [...this.unreadMessages];
      this.readMessages.splice(0,0, previousMessage);
      this.readMessages = [...this.readMessages];
    }
    this.expendedMessage = message_id;
    this.cd.detectChanges();
  }
}
