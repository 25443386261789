import { Injectable } from '@angular/core';
import {ToastComponent} from '../components/toast/toast.component';


@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastComponent!: ToastComponent;

  public show(toast: any) {
    if (this.toastComponent) {
      this.toastComponent.show(toast);
    }
  }
}
