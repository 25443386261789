import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessagesService} from "../../../../../core/services/messages.service";
import {Message} from "../../../../../core/models/message";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
    selector: 'app-signature-dialog',
    templateUrl: './signature-dialog.component.html',
    styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent {

    @Input() message?: Message;
    public form: FormGroup;
    public isSubmitted = false;
    public isWrong = false;

    constructor(
        private fb: FormBuilder,
        private messagesService: MessagesService,
        private cdr: ChangeDetectorRef,
    ) {
        this.form = this.fb.group({
            password: this.fb.control('', [Validators.required]),
        });
    }

    public doSign(): void {
        const password = this.form.get('password')?.value;

        this.form.setErrors(null);
        this.messagesService.signMessage(password, this.message?.file?.id)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.isWrong = true;
                    this.cdr.detectChanges();
                    return throwError(response);
                }))
            .subscribe(() => {
                this.isSubmitted = true;
                this.cdr.detectChanges();
            });
    }
}
