import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuMobileComponent implements OnInit {

  public current_user: User | null = null;
  public currentRoute = '';
  constructor(
    private authService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      this.cd.detectChanges();
    });
  }

}
