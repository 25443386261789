<div class="gray-container h-100">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12 col-md-4 offset-md-4 d-flex align-items-center">
                <div class="login-form">
                    <div class="logo">
                        <img class="img-fluid" src="assets/layout/images/logo/itknowation.png">
                    </div>
                    <div class="login-item">
                        <form [formGroup]="form" (ngSubmit)="doLogin()" class="form form-login">
                            <div class="e-input-group e-float-icon-left e-corner">

                                <div class="e-input-in-wrap">
                                    <span class="e-input-group-icon icon-user"></span>
                                    <input formControlName="email" class="e-input e-outline" type="text"
                                           placeholder="Email"/>
                                </div>
                            </div>

                            <div class="e-input-group e-float-icon-left e-corner">
                                <span class="e-input-group-icon icon-key"></span>
                                <div class="e-input-in-wrap">
                                    <input formControlName="password" class="e-input" type="password"
                                           placeholder="Password"/>
                                </div>
                            </div>
                            <div *ngIf="form.errors" class="text-center">
                              <span class="alert fw-bold">Benutzername und/oder Passwort sind falsch.</span>
                            </div>
                            <div  class="mb-3">
                                <button ejs-button cssClass="e-primary e-block" size="large" type="button" (click)="doLogin()">Log in</button>
                            </div>
                        </form>
                        <div class="reset-password text-center">
                            <a class="text-decoration-none fw-bold" (click)="onOpenDialog()">Passwort vergessen</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <ejs-dialog id='dialog' #ejDialog  header='Dialog' showCloseIcon='true' [visible]="false" (beforeOpen)="beforeOpen($event)" (beforeClose)="beforeClose($event)"
                    [target]='targetElement' width='500px'> <ng-template #content> <app-password-reset *ngIf="isOpened" ></app-password-reset></ng-template></ejs-dialog>
    </div>
</div>

