import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordRequestComponent {

  public form: FormGroup;
  public isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required]),
    });
  }

  public doReset(): void {
    const formValues = this.form.value;

    this.form.setErrors(null);
    this.authService.resetPasswordRequest(formValues).subscribe(() => {
      this.isSubmitted = true;
      this.cdr.detectChanges();
    });
  }
}
