<div class='content'>
    <div class="container">
        <div class="row">
            <div>
                <form [formGroup]="form" (ngSubmit)="doVacationRequest()" class="form hrep-vacation-form">
                    <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <label>{{ 'VACATION.EMPLOYEE' | translate }}</label>
                        <div class='textboxes'>
                            <ejs-textbox id="name" formControlName="user" [readonly]="true" [ngModel]="userFullName"
                                         cssClass="hrep-input"></ejs-textbox>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <label>{{ 'VACATION.START_DATE' | translate }}</label>
                        <div class="form-group">
                            <ejs-datepicker
                                id="startDate"
                                formControlName="from"
                                [min]='minStartDate'
                                strictMode='true'
                                format='dd.MM.yyyy'
                                (renderDayCell)='disabledDateWeekends($event)'
                                (change)="onStartDateChange($event)"
                                openOnFocus="true"
                                required
                                cssClass="hrep-input"
                            >
                            </ejs-datepicker>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <label>{{ 'VACATION.END_DATE' | translate }}</label>
                        <ejs-datepicker
                            id="endDate"
                            formControlName="to"
                            [enabled]=endDateDisabled
                            [min]='minEndDate'
                            [value]="minEndDateValue"
                            strictMode='true'
                            format='dd.MM.yyyy'
                            (renderDayCell)='disabledDateWeekends($event)'
                            openOnFocus="true"
                            required cssClass="hrep-input"
                        >
                        </ejs-datepicker>
                    </div>
                    <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <label>{{ 'VACATION.APPROVAL_STATUS' | translate }}</label>
                        <div class='textboxes'>
                            <ejs-textbox id="approvalStatus" formControlName="approval_state" [ngModel]="vacationRequestStatus" [readonly]="true" cssClass="hrep-input border-0 bg-white"></ejs-textbox>
                        </div>
                    </div>
                    <div class="form-field text-center">
                        <button [disabled]="form.invalid || submitInProgress" cssClass="e-small" ejs-button type="submit" class="e-btn e-primary">{{ 'VACATION.SEND_REQUEST' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

