import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup;
  private token: string | null | undefined;
  private email: string | null | undefined;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router) {

    this.form = this.fb.group({
      password: this.fb.control('', [Validators.required]),
      passwordConfirmation: this.fb.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.route.queryParams
      .subscribe(params => {
          this.email = params.email;
        }
      );
  }

  public doReset(): void {
    const formValues = this.form.value;

    this.form.setErrors(null);

    this.authService.resetPassword(formValues, this.token, this.email).subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

}
